.container {
  margin-top: -12px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.normal {
  composes: title;
}

.danger {
  composes: title;
  color: rgb(242, 65, 65);
}

.table {
  width: 100%;
}

.cell {
  height: auto;
  word-wrap: break-word;
  word-break: break-all;
}

.endpoint, .labels {
  composes: cell;
  width: 25%;
}

.state, .last-scrape {
  composes: cell;
  width: 10%;
}

.errors {
  composes: cell;
  width: 30%;
}
